<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      clipped-left
      class="app-bar d-print-none"
    >
      <div class="d-flex align-center justify-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/puet-logo-small.png')"
          transition="scale-transition"
          width="40"
        />

        <h1 class="text-h6 text-sm-h5 text-md-h4 text-lg-h site-title">
          {{ $t("title")}}
        </h1>
      </div>

      <v-spacer></v-spacer>
      <LangSwitcherButton/>
      <DarkModeButton/>
    </v-app-bar>

    <v-main>
      <ScheduleForm/>
      <component :is="scheduleComponent"></component>
      <FooterMobile v-if="this.$vuetify.breakpoint.mobile"/>
    </v-main>
  </v-app>
</template>

<script>
import ScheduleResults from '@/components/ScheduleResults';
import ScheduleResultsMobile from '@/components/ScheduleResultsMobile';
import ScheduleForm from "@/components/ScheduleForm";
import LangSwitcherButton from "@/components/LangSwitcherButton";
import DarkModeButton from "@/components/DarkModeButton";
import FooterMobile from "@/components/FooterMobile";

export default {
  name: 'App',

  components: {
    ScheduleForm,
    ScheduleResults,
    ScheduleResultsMobile,
    LangSwitcherButton,
    DarkModeButton,
    FooterMobile
  },

  data: () => ({
    darkMode: false,
  }),

  computed: {
    scheduleComponent() {
      return this.$vuetify.breakpoint.mobile ? "ScheduleResultsMobile" : "ScheduleResults"
    }
  },

  metaInfo() {
    return {
      title: this.$t("seo.title"),
      meta: [
        {name: 'description', content: this.$t("seo.description")}
      ],
    }
  },

  mounted() {
    this.darkMode = localStorage.darkMode ? localStorage.darkMode : window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    this.$vuetify.theme.dark = this.darkMode === "true"
    this.$moment.locale(this.$i18n.locale)
  }
};
</script>

<style scoped>
  .site-title {
    line-height: 1.25em;
  }

  .v-app-bar {
    color: white;
  }

  .v-app-bar.theme--dark {
    background: #1f4a6e !important;
  }

  @media print {
    .v-main {
      padding-left: 0 !important;
    }
  }
</style>

import { defineStore } from "pinia"
import axios from 'axios';
import { useLocaleState } from "@/composables/locale";
import i18n from '@/i18n'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

export const useScheduleStore = defineStore({
    id: "schedule",
    state: () => ({
        info: "No results",
        clear: true,
        empty: true,
        loading: false,
        tableAll: [],
        table: [],
        filterData: {
            'idPrep': '',
            'idRoom': '',
            'groups': [],
            'dateStart': '',
            'dateEnd': '',
        },
        heading: {
            1: {
                's': '8:00',
                'e': '9:20',
            },
            2: {
                's': '9:30',
                'e': '10:50',
            },
            3: {
                's': '11:00',
                'e': '12:20',
            },
            4: {
                's': '12:40',
                'e': '14:00',
            },
            5: {
                's': '14:10',
                'e': '15:30',
            },
            6: {
                's': '15:40',
                'e': '17:00',
            },
            7: {
                's': '17:05',
                'e': '18:25',
            },
            8: {
                's': '18:30',
                'e': '19:50',
            },
            9: {
                's': '19:55',
                'e': '21:15',
            },
            10: {
                's': '21:20',
                'e': '22:40',
            }

        }
    }),
    actions: {
        /**
         * Refresh schedule if language changed
         * @returns {Promise<void>}
         */
        async refreshSchedule() {
            if (this.clear) { return }

            await this.getSchedule(true)
        },

        /**
         * Get schedule
         * @returns {Promise<void>}
         */
        async getSchedule(refresh = false) {
            const teacher = this.filterData['idPrep']
            const room = this.filterData['idRoom']
            let groups = ''
            if (this.filterData['groups']) {
                groups = Array.isArray(this.filterData['groups']) ? this.filterData['groups'] : [this.filterData['groups']]
            }

            const date_s = this.filterData['dateStart']
            const date_e = this.filterData['dateEnd']

            this.loading = true
            this.clear = true

            const teacherFilter = teacher ? `&teacher=${teacher}` : ''
            const roomFilter = room ? `&room=${room}` : ''
            const { shortLocale } = useLocaleState();

            try {

                let endpoints = [];
                if (groups) {
                    endpoints = groups.map((group) => {
                        const groupFilter = group ? `&course=${group.course}&spec_id=${group.spec_id}&num=${group.num}&forma=${group.forma}&owner=${group.owner}` : ''
                        return `https://cabinet.puet.edu.ua/api/staging/schedule/classes/table/?date_s=${date_s}&date_e=${date_e}&language=${shortLocale.value}${teacherFilter}${roomFilter}${groupFilter}`
                })} else {
                    endpoints.push(`https://cabinet.puet.edu.ua/api/staging/schedule/classes/table/?date_s=${date_s}&date_e=${date_e}&language=${shortLocale.value}${teacherFilter}${roomFilter}`)
                }

                await Promise.all(endpoints.map((endpoint) => {
                    return axios.get(
                        endpoint,
                        {
                            headers: {
                                token: '4ydwkwfkjj02yzgwsd9a',
                            }
                        },
                    )
                })).then(responses => {
                    this.table = []
                    this.tableAll = []

                    responses.map(({data}) => {
                        this.table.push(data)

                        // Merge schedule data if 2 or more tables received
                        if (responses.length > 1) {
                            this.tableAll = data.reduce((acc, cur) => {
                                let ins = acc.find(e=>e.date===cur.date)
                                if (ins) {
                                    ins.classes = ins.classes.concat(cur.classes)
                                    ins.classes.sort((a, b) => a.num - b.num)
                                } else {
                                    acc.push({...cur})
                                }
                                return acc
                            }, this.tableAll)
                        }
                    });

                    if (this.tableAll.length) {
                        if (refresh) {
                            this.table.shift()
                            this.filterData.groups.shift()
                        }

                        // Order by date
                        this.tableAll.sort((a, b) => Number(a.date > b.date))

                        // Move to main array
                        this.table.unshift(this.tableAll)
                        this.filterData.groups.unshift({'name': i18n.t("schedule.all")})
                    }

                    this.clear = false
                    this.empty = responses.length;
                    this.loading = false
                })

            } catch (error) {
                console.log(error)
            }
        }
    }
})

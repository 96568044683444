<template>
  <v-navigation-drawer
      :width="this.$vuetify.breakpoint.mobile ? '100vw' : '320'"
      :height="this.$vuetify.breakpoint.mobile ? 'auto' : '100vh'"
      :app="!this.$vuetify.breakpoint.mobile"
      :clipped="!this.$vuetify.breakpoint.mobile"
      permanent
      class="schedule-form-container d-print-none"
  >
    <v-form v-on:submit.prevent="getSchedule">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-banner
                single-line
            >
              <v-icon
                  color="orange darken-4"
                  size="28"
              >
                mdi-magnify-scan
              </v-icon>
              {{ $t("filters.title") }}
            </v-banner>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-menu
                v-model="dateStartPicker"
                :close-on-content-click="false"
                :nudge-right="this.$vuetify.breakpoint.mobile?25:35"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateStart"
                    :label="$t('filters.start_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="dateStart"
                  first-day-of-week="1"
                  show-current
                  :locale="$i18n.locale"
                  @input="dateStartPicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
                v-model="dateEndPicker"
                :close-on-content-click="false"
                :nudge-right="this.$vuetify.breakpoint.mobile?0:35"
                :left="this.$vuetify.breakpoint.mobile"
                :origin="this.$vuetify.breakpoint.mobile?'top right':'top left'"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateEnd"
                    :label="$t('filters.end_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="dateEnd"
                  first-day-of-week="1"
                  :locale="$i18n.locale"
                  show-current
                  @input="dateEndPicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0 mt-0">
            <v-autocomplete
                clearable
                return-object
                open-on-clear
                hide-no-data
                auto-select-first
                v-model="filterTeacher"
                :items="dataTeachers"
                item-value="id_prep"
                :item-text="$i18n.locale === 'uk' ? 'name' : 'name_en'"
                :label="$t('filters.teacher')"
                class="pt-0 mt-0"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
            <v-autocomplete
                clearable
                open-on-clear
                hide-no-data
                auto-select-first
                return-object
                v-model="filterRoom"
                :items="dataRooms"
                item-value="id"
                item-text="name"
                :label="$t('filters.room')"
                class="pt-0 mt-0"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
            <v-autocomplete
                clearable
                open-on-clear
                hide-no-data
                return-object
                auto-select-first
                multiple
                chips
                deletable-chips
                v-model="filterGroup"
                :items="dataGroups"
                item-value="hash"
                item-text="name"
                :label="$t('filters.group')"
                class="pt-0 mt-0"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0 mt-0">
            <v-checkbox
                v-model="filterRemember"
                :label="$t('filters.remember')"
                color="secondary"
                hide-details
                class="pt-0 mt-0"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" >
            <v-divider></v-divider>
          </v-col>
          <v-col>
            <v-btn
                class="mr-4"
                type="submit"
                color="info"
            >
              {{ $t('buttons.get')}}
            </v-btn>
            <v-btn
                @click="clear"
                class="float-right"
            >
              {{ $t('buttons.clear')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <div class="copyright desktop" v-if="!this.$vuetify.breakpoint.mobile">
      <p>Copyright © {{ currentYear }} - {{ $t("seo.university") }}</p>
      <p>Made by Dmytro Olkhovskyi with<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg></p>
    </div>
  </v-navigation-drawer>
</template>

<script>
import {useScheduleStore} from "@/stores/schedule";

export default {
  name: "ScheduleForm",

  setup: () => {
    const schedule = useScheduleStore()

    return {
      schedule
    }
  },

  data: () => ({
    dataTeachers: [],
    filterTeacher: null,

    dataGroups: [],
    filterGroup: null,

    dataRooms: [],
    filterRoom: null,

    filterRemember: localStorage.filterRemember,

    dateStartPicker: false,
    dateStart: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dateEndPicker: false,
    dateEnd: (new Date(Date.now() + 7 * 24 * 60 * 60 * 1000 - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

    currentYear: new Date().getFullYear(),
  }),

  methods: {
    clearLocalStorage() {
      ['filterTeacher', 'filterRoom', 'filterGroups', 'filterRemember'].forEach(key => localStorage.removeItem(key))
    },

    // Filter Form Clear Action
    async clear () {
      this.dateStart = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.dateEnd = (new Date(Date.now() + 7 * 24 * 60 * 60 * 1000 - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.schedule.table = []
      this.schedule.clear = true
      this.filterTeacher = null
      this.filterGroup = null
      this.filterRoom = null
      this.filterRemember = false

      this.clearLocalStorage()
    },

    async getSchedule () {
      const idPrep = this.filterTeacher != null ? this.filterTeacher.id_prep : null
      const idRoom = this.filterRoom != null ? this.filterRoom.id : null
      //const idGroup = this.filterGroup != null ? this.filterGroup.hash : null

      let idsGroup = null;
      if (this.filterGroup != null && this.filterGroup.length) {
        idsGroup = this.filterGroup.map((item) => {
          return item.hash
        })
      }

      if (this.filterRemember) {
        localStorage.filterTeacher = idPrep
        localStorage.filterRoom = idRoom
        localStorage.filterGroups = idsGroup
        localStorage.filterRemember = true
      } else {
        this.clearLocalStorage()
      }

      this.schedule.filterData['dateStart'] = this.dateStart;
      this.schedule.filterData['dateEnd'] = this.dateEnd;
      this.schedule.filterData['idPrep'] = idPrep;
      this.schedule.filterData['idRoom'] = idRoom;
      if (this.filterGroup) {
        if (Array.isArray(this.filterGroup) && !this.filterGroup.length) {
          this.schedule.filterData['groups'] = ''
        } else {
          this.schedule.filterData['groups'] = [...this.filterGroup];
        }
      } else {
        this.schedule.filterData['groups'] = ''
      }

      await this.schedule.getSchedule()

      await this.$vuetify.goTo('#schedule-results')
    },
    /**
     * API data call
     * @param {string} call
     */
    async getApiData(call) {
      try {
        const response = await this.$http.get(
            `https://cabinet.puet.edu.ua/api/v1/schedule/${call}`,
            {
              headers: {
                token: '4ydwkwfkjj02yzgwsd9a',
              }
            },
        )
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
  },

  async created () {
    this.dataTeachers = await this.getApiData('teachers')
    this.dataRooms = await this.getApiData('rooms')
    this.dataGroups = await this.getApiData('groups')
  },

  watch: {
    dataTeachers(value) {
      if (value.length && localStorage.filterTeacher) {
        this.filterTeacher = this.dataTeachers.find(x => x.id_prep === parseInt(localStorage.filterTeacher))
      }
    },
    dataRooms(value) {
      if (value.length && localStorage.filterRoom) {
        this.filterRoom = this.dataRooms.find(x => x.id === parseInt(localStorage.filterRoom))
      }
    },
    dataGroups(value) {
      if (value.length && localStorage.filterGroups && localStorage.filterGroups !== 'null') {
        const groups = localStorage.filterGroups.split(',')
        this.filterGroup = groups.map((e) => {
          return this.dataGroups.find(x => x.hash === e)
        })

      }
    }
  }
}
</script>

<style scoped>
  .schedule-form-container {
    background-color: rgba(33, 150, 243, .1);
  }

  .copyright.desktop {
    position: absolute;
    bottom: 0;
    left: 10px;
  }

  .copyright {
    font-size: 0.8em;
    line-height: 16px;
  }

  .copyright svg{
    color: #e7515a;
    fill: rgba(231, 81, 90, 0.4196078431);
    height: 14px;
    position: relative;
    top: 3px;
  }
</style>
